<template>
  <button
    type="button"
    class="relative bg-cl-transparent brdr-none inline-flex"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <i class="icon-kup icon-size cl-light-gray visible-xs-and-tablet" />
    <i class="icon-buy icon-size cl-alternative hidden-xs-and-tablet" />
    <span
      class="minicart-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-primary-orange"
      v-cloak
      v-show="totalQuantity"
      data-testid="minicartCount"
    >
      {{ totalQuantity }}
    </span>
  </button>
</template>

<script>
import MicrocartIcon from '@vue-storefront/core/compatibility/components/blocks/Header/MicrocartIcon'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [MicrocartIcon],
  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.$store.dispatch('cart/load')
      }
    })
  },
  computed: {
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    })
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    })
  }
}
</script>

<style lang="scss" scoped>
  .icon-size {
    font-size: 32px;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
  .minicart-count {
    top: -5px;
    left: 70%;
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
    @media (max-width: 768px) {
      top: 7px;
      left: 50%;
    }
  }
</style>
